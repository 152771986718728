import * as fromReducers from '../store/reducers';
import {AccountRestService} from './api/account.rest-service';
import {Directive, Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {InitLoadAllAccounts} from '../store/actions/account.actions';
import {AccountsPaginationRequestModel} from '../models/accounts-pagination-request.model';
import {AccountModel} from '../models/account.model';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class AccountService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();
    public currentAccountsRequest: AccountsPaginationRequestModel = new AccountsPaginationRequestModel();
    private accounts$: Observable<any>;

    constructor(public store: Store<fromReducers.State>,
                public accountRestService: AccountRestService) {
    }
    searchAccounts(pageable: AccountsPaginationRequestModel) {
        this.currentAccountsRequest = pageable;
        this.loadAllAccounts();
    }

    loadAllAccounts(): Observable<any[]> {
        const loadAccountsAAction: InitLoadAllAccounts = new InitLoadAllAccounts(this.currentAccountsRequest);
        this.store.dispatch(loadAccountsAAction);
        return this.getAllAccountsObservable();
    }

    getAllAccountsObservable(): Observable<any[]> {
        return this.store.pipe(select(fromReducers.getAccounts));
    }

    getAllAccountsLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getAllAccountsLoading));
    }

    getAllAccountsErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getAllAccountsError));
    }

    getAllAccountsResponseObservable(): Observable<AccountModel[]> {
        this.accounts$ = this.store.pipe(select(fromReducers.getAllAccountsResponse));
        return this.accounts$;
    }

    getAllAccountsRequestObservable(): Observable<AccountsPaginationRequestModel> {
        return this.store.pipe(select(fromReducers.getAllAccountsRequest));
    }

    page(pageable: AccountsPaginationRequestModel) {
        this.currentAccountsRequest = pageable;
        this.loadAllAccounts();
    }

    exportAccounts(pageable: any): Observable<any> {
        return this.accountRestService.exportAccounts(pageable);
    }

    countAccounts(): Observable<any> {
        return this.accountRestService.count();
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

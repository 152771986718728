import {Action} from '@ngrx/store';

export enum PeriodActionTypes {
    INIT_LOAD_VENDOR_PERIODS_BY_CATEGORY = '[Preorder-period] Init Load Vendor Periods by Category',
    LOAD_VENDOR_PERIODS_BY_CATEGORY_COMPLETE = '[Preorder-period] Load Vendor Periods by Category Complete',
    LOAD_VENDOR_PERIODS_BY_CATEGORY_FAILED = '[Preorder-period] Load Vendor Periods by Category Failed',
    INIT_ADD_PERIOD = '[Preorder-period] Init Add Period',
    ADD_PERIOD_COMPLETE = '[Preorder-period] Add Period Complete',
    ADD_PERIOD_FAILED = '[Preorder-period] Add Period Failed',
    INIT_EDIT_PERIOD = '[Preorder-period] Init Edit Period',
    EDIT_PERIOD_COMPLETE = '[Preorder-period] Edit Period Complete',
    EDIT_PERIOD_FAILED = '[Preorder-period] Edit Period Failed',
}

export class InitLoadVendorPeriodsByCategory implements Action {
    readonly type = PeriodActionTypes.INIT_LOAD_VENDOR_PERIODS_BY_CATEGORY;

    constructor(public payload: any) {
    }
}

export class LoadVendorPeriodsByCategoryComplete implements Action {
    readonly type = PeriodActionTypes.LOAD_VENDOR_PERIODS_BY_CATEGORY_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadVendorPeriodsByCategoryFailed implements Action {
    readonly type = PeriodActionTypes.LOAD_VENDOR_PERIODS_BY_CATEGORY_FAILED;

    constructor(public payload: any) {
    }
}

export class InitAddPeriod implements Action {
    readonly type = PeriodActionTypes.INIT_ADD_PERIOD;
}

export class AddPeriodComplete implements Action {
    readonly type = PeriodActionTypes.ADD_PERIOD_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddPeriodFailed implements Action {
    readonly type = PeriodActionTypes.ADD_PERIOD_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditPeriod implements Action {
    readonly type = PeriodActionTypes.INIT_EDIT_PERIOD;
}

export class EditPeriodComplete implements Action {
    readonly type = PeriodActionTypes.EDIT_PERIOD_COMPLETE;

    constructor(public payload: any) {
    }
}

export class EditPeriodFailed implements Action {
    readonly type = PeriodActionTypes.EDIT_PERIOD_FAILED;

    constructor(public payload: any) {
    }
}

export type PeriodActions =
    InitLoadVendorPeriodsByCategory |
    LoadVendorPeriodsByCategoryComplete |
    LoadVendorPeriodsByCategoryFailed |
    InitAddPeriod |
    AddPeriodComplete |
    AddPeriodFailed |
    InitEditPeriod |
    EditPeriodComplete |
    EditPeriodFailed;

import {PeriodActions, PeriodActionTypes} from '../../actions/preorder/period.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {PeriodModel} from '../../../models/preorder/period.model';

export interface State {
    preorderPeriodsByCategory: PeriodModel[];
    getPreorderPeriodsByCategoryLoading: boolean;
    getPreorderPeriodsByCategoryError: HttpErrorResponse;
    addPeriodError: HttpErrorResponse;
    addPeriodLoading: boolean;
    editPeriodError: HttpErrorResponse;
    editPeriodLoading: boolean;
}

export const initialState: State = {
    preorderPeriodsByCategory: [],
    getPreorderPeriodsByCategoryLoading: false,
    getPreorderPeriodsByCategoryError: null,
    addPeriodLoading: false,
    addPeriodError: null,
    editPeriodLoading: false,
    editPeriodError: null,
};

export function reducer(state = initialState, action: PeriodActions): State {
    switch (action.type) {
        case PeriodActionTypes.INIT_LOAD_VENDOR_PERIODS_BY_CATEGORY:
            return {
                ...state,
                getPreorderPeriodsByCategoryLoading: true,
                getPreorderPeriodsByCategoryError: null
            };
        case PeriodActionTypes.LOAD_VENDOR_PERIODS_BY_CATEGORY_COMPLETE:
            return {
                ...state,
                getPreorderPeriodsByCategoryLoading: false,
                preorderPeriodsByCategory: action.payload
            };
        case PeriodActionTypes.LOAD_VENDOR_PERIODS_BY_CATEGORY_FAILED:
            return {
                ...state,
                getPreorderPeriodsByCategoryLoading: false,
                getPreorderPeriodsByCategoryError: action.payload
            };
        case PeriodActionTypes.INIT_ADD_PERIOD:
            return {
                ...state,
                addPeriodLoading: true,
                addPeriodError: null
            };
        case PeriodActionTypes.ADD_PERIOD_COMPLETE:
            return {
                ...state,
                addPeriodLoading: false,
                addPeriodError: null,
            };
        case PeriodActionTypes.ADD_PERIOD_FAILED:
            return {
                ...state,
                addPeriodLoading: false,
                addPeriodError: action.payload
            };
        case PeriodActionTypes.INIT_EDIT_PERIOD:
            return {
                ...state,
                editPeriodLoading: true,
                editPeriodError: null
            };
        case PeriodActionTypes.EDIT_PERIOD_COMPLETE:
            return {
                ...state,
                editPeriodLoading: false,
                editPeriodError: null,
            };
        case PeriodActionTypes.EDIT_PERIOD_FAILED:
            return {
                ...state,
                editPeriodLoading: false,
                editPeriodError: action.payload
            };
        default:
            return state;
    }
}

export const getPreorderPeriodsByCategory = (state: State) => state.preorderPeriodsByCategory;
export const getPreorderPeriodsByCategoryLoading = (state: State) => state.getPreorderPeriodsByCategoryLoading;
export const getPreorderPeriodsByCategoryError = (state: State) => state.getPreorderPeriodsByCategoryError;
export const getAddPeriodLoading = (state: State) => state.addPeriodLoading;
export const getAddPeriodError = (state: State) => state.addPeriodError;
export const getEditPeriodLoading = (state: State) => state.editPeriodLoading;
export const getEditPeriodError = (state: State) => state.editPeriodError;

import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';
import {CategoryRequest} from '../../models/preorder/category-request.model';
import {SupplierRequest} from '../../models/preorder/supplier-request.model';

@Injectable({
    providedIn: 'root',
})
export class PreorderRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    /* ======= Category ========= */

    public loadAllCategories() {
        return this.get('/resource/preorder/admin/category').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public addCategory(request: CategoryRequest) {
        return this.post(`/resource/preorder/admin/category`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadCategory(url: string) {
        return this.get(`/resource/preorder/admin/category/${url}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updateCategory(rq: CategoryRequest, url: string): Observable<Response> {
        return this.put(`/resource/preorder/admin/category/${url}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadVendorPeriodsByCategory(url: string) {
        return this.get(`/resource/preorder/admin/preorderperiod/${url}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    /* ======== Supplier ========= */

    public loadAllSuppliers() {
        return this.get('/resource/admin/vendors').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public addSupplier(rq: SupplierRequest) {
        return this.post(`/resource/admin/vendors`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updateSupplier(rq: SupplierRequest, vendorId): Observable<Response> {
        return this.put(`/resource/admin/vendors/${vendorId}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public addPeriod(rq: any) {
        return this.post(`/resource/preorder/admin/preorderperiod`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updatePreorderPeriod(rq: any) {
        const id = rq.preorderPeriodId;
        delete rq.preorderPeriodId;
        return this.put(`/resource/preorder/admin/preorderperiod/${id}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    /* ======== Products ========= */

    public addProduct(preOrderPeriodId, rq: any) {
        return this.post(`/resource/preorder/admin/preorderproduct/${preOrderPeriodId}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updateProduct(rq: any, pzn, preOrderPeriodId): Observable<Response> {
        return this.put(`/resource/preorder/admin/preorderproduct/${pzn}/${preOrderPeriodId}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public deleteProduct(pzn, preOrderPeriodId): Observable<Response> {
        return this.delete(`/resource/preorder/admin/preorderproduct/${pzn}/${preOrderPeriodId}`, {
            params: {
                pzn: pzn,
                preOrderPeriodId: preOrderPeriodId
            }
        }).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadAllProductsByPeriod(preOrderPeriodId) {
        return this.get(`/resource/preorder/admin/preorderproduct/${preOrderPeriodId}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    /* ======== Preorders ========= */

    public loadAllPreordersByCategory(url) {
        return this.get(`/resource/preorder/admin/preorder/${url}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    /* ======== Preorder Products ========= */

    public loadProductsByPreorderId(preOrderId) {
        return this.get(`/resource/preorder/admin/preorderitems/${preOrderId}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

}
